import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import FigureWithGradientPlaceholder from "../components/ui/FigureWithGradientPlaceholder";
import SEO from "../components/seo";

import StripeButton from "../components/ui/StripeButton";
import { _linkResolver } from "../core/utils";
// import ImgWithDominantPlaceholder from "../components/ui/ImgWithDominantPlaceholder";

// import { WrapperContext } from "../components/Layout";

export const pageQuery = graphql`
  query ProductBySlug($uid: String!) {
    prismicProduct(uid: { eq: $uid }) {
      uid
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        title {
          text
        }
        texte {
          raw
        }
        texte_detail {
          raw
        }
        footnotes {
          index
          texte {
            raw
          }
        }
        reference
        editor
        sku
        price
        shipping
        year
        type
        sold_out
        images {
          image {
            # ...sharp
            ...sharpThumbnail870
          }
        }
        related {
          document {
            ... on PrismicProduct {
              uid
              type
              data {
                image_featured {
                  # ...sharp
                  ...sharpThumbnail
                }
              }
            }
          }
        }
      }
    }
  }
`;

// let isLoading = false;

const PageProduct = ({ path, pageContext, data }) => {
  const { template } = pageContext;
  // const { uid } = data.prismicProduct;
  const {
    meta_title,
    meta_description,
    meta_image,
    title,
    texte,
    texte_detail,
    footnotes,
    type,
    year,
    reference,
    editor,
    sku,
    price,
    shipping,
    sold_out,
    images,
    related,
  } = data.prismicProduct.data;

  //Removing the first element
  // if (images.length > 1) images.shift();

  const [isLoading, setIsLoading] = useState(false);
  const [imagesInfinite, setImagesInfinite] = useState(images);
  const [showRelated, setShowRelated] = useState();

  // const { isMobile } = useContext(WrapperContext);
  // console.log("isMobile", isMobile);
  useEffect(() => {
    const token = PubSub.subscribe("SCROLL_BOTTOM", (e) => {
      // console.log(e, isLoading);

      if ("ontouchstart" in window || navigator.maxTouchPoints) {
        setShowRelated(true);
        return;
      }

      if (!isLoading) {
        setIsLoading(true);
        setImagesInfinite((previousImages) => [...previousImages, ...images]);
        setIsLoading(false);

        setShowRelated(true);
      }
    });

    // setTimeout(_format, 150);
    // window.addEventListener("resize", _format);
    return () => {
      // window.removeEventListener("resize", _format);
      PubSub.unsubscribe(token);
    };
  }, [isLoading, setIsLoading, images]);

  // const _format = () => {
  //   const texteDetailBounding = document
  //     .querySelector(".sticky .top")
  //     .getBoundingClientRect();

  //   const thubmnails = document.querySelector(".thubmnails");
  //   // console.log(thubmnails);
  //   if (thubmnails)
  //     thubmnails.style.setProperty(
  //       "--texte-top",
  //       texteDetailBounding.height + "px"
  //     );
  //   thubmnails.style.opacity = 1;
  // };

  const _getImgRelativeDimensions = (image) => {
    const height = 37;
    let width = height * image.fluid?.aspectRatio;

    return { width, height };
  };

  const _scrollTo = (index) => {
    const target = document.querySelector(`.image-${index}`);
    if (target) {
      target.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  console.log(footnotes);
  return (
    <div className='page-template page-product bg-gray-'>
      <SEO
        pageTitle={meta_title.text}
        pageDescription={meta_description.text}
        pageImage={meta_image.url}
        template={`${template} `}
        page={true}
        pathname={path}
        documentClass='bg-gray'
      />

      <div className='header px-sm md:px-md mb-sm md:sticky hidden-sm'>
        <div
          className='row custom-gutter'
          style={{
            "--custom-gutter": "17px",
          }}>
          <div className='col-auto col-site-name'></div>
          <div className='col-auto col-h1 uppercase'>
            <Link to={"/shop"}>SHOP</Link>
          </div>
          <div className='col-auto col-metas'>
            <div className='flex justify-between'>
              <span>{type}</span>
              <span>
                <span className='px-custom-gutter'>{reference}</span>
                <span className='px-custom-gutter'>{editor}</span>
              </span>
              <span>{year}</span>
            </div>
          </div>
          <h1 className='col-xs col-images'>
            {title.text}
            {/* <sup className='text-secondary italic'>01</sup> */}
          </h1>
        </div>
      </div>

      <div className='header px-sm md:px-md mb-sm sticky sm-only'>
        <div
          className='row custom-gutter'
          style={{
            "--custom-gutter": "17px",
          }}>
          <div className='col-auto col-h1 uppercase'>
            <Link to={"/shop"}>SHOP</Link>
          </div>
          <div className='col-auto col-metas'>{type}</div>
        </div>
        <div
          className='row custom-gutter'
          style={{
            "--custom-gutter": "17px",
          }}>
          <div className='col-auto col-reference'>{reference}</div>
          <div className='col-auto col-editor'>{editor}</div>
          <div className='col-auto col-year'>{year}</div>
          <h1 className='col-xs col-title'>
            {title.text}
            {/* <sup className='text-secondary italic'>01</sup> */}
          </h1>
        </div>
      </div>

      <div className='body px-sm md:px-md'>
        <div
          className='row custom-gutter'
          style={{
            "--custom-gutter": "17px",
          }}>
          <div className='col-auto col-site-name'>
            <div className='md:sticky'>
              {/* <div className='thubmnails flex flex-wrap items-baseline hidden-sm'>
                {images.map(({ image }, i) => (
                  <div
                    className='thumbnail  cursor-pointer '
                    key={i}
                    onClick={() => _scrollTo(i)}
                    style={{
                      width: _getImgRelativeDimensions(image).width,
                    }}>
                    <FigureWithGradientPlaceholder input={image} />
                  </div>
                ))}
              </div> */}
            </div>
          </div>
          <div className='col-auto col-h1'></div>
          <div className='col-auto col-metas'>
            <div className='md:sticky'>
              <div className='top flex md:flex-col justify-between'>
                <div className='mb-sm cstm-fixed-h'>
                  <RichText render={texte.raw} linkResolver={_linkResolver} />
                </div>

                <div className='flex-shrink-0'>
                  {sold_out ? (
                    <button className='add-to-cart text-lg text-secondary italic pointer-events-none'>
                      sold out
                    </button>
                  ) : (
                    <>
                      <StripeButton
                        price={price}
                        shipping={shipping}
                        sku={sku}
                      />{" "}
                      {/* <sup className='index text-secondary italic absolute'>
                        01
                      </sup> */}
                    </>
                    // <button className='add-to-cart text-lg text-secondary italic pointer-events-none'>
                    //   stripe
                    // </button>
                  )}
                </div>
              </div>
              {texte_detail.text && (
                <div className='text-xs texte-detail mb-sm'>
                  <RichText render={texte_detail.raw} />
                </div>
              )}
              <ul className='footnotes px-sm md:px-0 hidden-sm'>
                {footnotes.map((li, i) => (
                  <li key={i} className='flex items-baseline mb-sm relative'>
                    <div className='index text-secondary italic absolute'>
                      {li.index}
                    </div>
                    <div className='text-xs-'>
                      <RichText render={li.texte.raw} />
                    </div>
                  </li>
                ))}
              </ul>

              {related && related.document && (
                <div
                  className={clsx(
                    "product-related hidden-sm----",
                    showRelated ? "reveal" : ""
                  )}>
                  <Link to={_linkResolver(related.document)}>
                    <FigureWithGradientPlaceholder
                      input={related.document.data.image_featured}
                    />
                    <h2 className='text-secondary italic text-sm py-xs'>
                      next article
                    </h2>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className='col-xs col-images'>
            {imagesInfinite.map(({ image }, i) => (
              <figure className={clsx("mb-sm", `image-${i}`)} key={i}>
                <FigureWithGradientPlaceholder input={image} />
                {/* <Img {...image} /> */}
              </figure>
            ))}

            {/* {related && related.document && (
              <div
                className={clsx(
                  "product-related sm-only mb-screen-h",
                  showRelated ? "reveal" : ""
                )}>
                <Link to={_linkResolver(related.document)}>
                  <ImgWithDominantPlaceholder
                    input={related.document.data.image_featured}
                  />
                  <h2 className='text-secondary italic text-sm py-xs'>
                    next article
                  </h2>
                </Link>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPreview(PageProduct);
