// npm install @stripe/stripe-js
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

const GATSBY_STRIPE_PUBLIC_API_KEY = process.env.GATSBY_STRIPE_PUBLIC_API_KEY;

const StripeButton = ({ price, shipping, sku }) => {
  const [loading, setLoading] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const [stripe, setStripe] = useState(null);

  useEffect(async () => {
    if (!stripe) {
      const stripeTmp = await loadStripe(GATSBY_STRIPE_PUBLIC_API_KEY);
      setStripe(stripeTmp);
    }
  });

  const handleClick = async (event) => {
    event.preventDefault();
    setLoading(true);

    setStripeError(null);

    // const zones = "AF", "AX", "AL", "DZ", "AS", "AD", "AO", "AI", "AQ", "AG", "AR", "AM", "AW", "AU", "AT", "A", "BS", "BH", "BD", "BB", "BY", "BE", "BZ", "BJ", "BM", "BT", "BO", "BA", "BW", "BV", "BR", "VG", "IO", "BN", "BG", "BF", "BI" "KH", "CM", "CA", "CV", "KY", "CF", "TD", "CL", "CN", "HK", "MO", "CX", "CC", "CO", "KM", "CG", "CD", "CK", "CR", "CI", "HR", "CU", "CY", "C", "DK", "DJ", "DM", "DO" "EC", "EG", "SV", "GQ", "ER", "EE", "ET" "FK", "FO", "FJ", "FI", "FR", "GF", "PF", "TF" "GA", "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GD", "GP", "GU", "GT", "GG", "GN", "GW", "GY" "HT", "HM", "VA", "HN", "HU" "IS", "IN", "ID", "IR", "IQ", "IE", "IM", "IL", "IT" "JM", "JP", "JE", "JO" "KZ", "KE", "KI", "KP", "KR", "KW", "KG" "LA", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU" "MK", "MG", "MW", "MY", "MV", "ML", "MT", "MH", "MQ", "MR", "MU", "YT", "MX", "FM", "MD", "MC", "MN", "ME", "MS", "MA", "MZ", "MM" "NA", "NR", "NP", "NL", "AN", "NC", "NZ", "NI", "NE", "NG", "NU", "NF", "MP", "NO" "OM" "PK", "PW", "PS", "PA", "PG", "PY", "PE", "PH", "PN", "PL", "PT", "PR" "QA" "RE", "RO", "RU", "RW" "BL", "SH", "KN", "LC", "MF", "PM", "VC", "WS", "SM", "ST", "SA", "SN", "RS", "SC", "SL", "SG", "SK", "SI", "SB", "SO", "ZA", "GS", "SS", "ES", "LK", "SD", "SR", "SJ", "SZ", "SE", "CH", "SY" "TW", "TJ", "TZ", "TH", "TL", "TG", "TK", "TO", "TT", "TN", "TR", "TM", "TC", "TV" "UG", "UA", "AE", "GB", "US", "UM", "UY", "U", "VU", "VE", "VN", "VI" "WF", "EH" "YE", "ZM", "ZW"
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      // shipping_rates: ["shr_1JsmrOGrzPnDVbglaliJRucG"],
      lineItems: [
        {
          price: sku,
          quantity: 1,
          // adjustable_quantity: {
          //   enabled: true,
          //   minimum: 1,
          //   maximum: 10,
          // },
        },
      ],
      successUrl: `${window.location.origin}/order-success`,
      cancelUrl: `${window.location.origin}/shop`,
      billingAddressCollection: "required",
      shippingAddressCollection: {
        allowedCountries: [
          "AC",
          "AD",
          "AE",
          "AF",
          "AG",
          "AI",
          "AL",
          "AM",
          "AO",
          "AQ",
          "AR",
          "AT",
          "AU",
          "AW",
          "AX",
          "AZ",
          "BA",
          "BB",
          "BD",
          "BE",
          "BF",
          "BG",
          "BH",
          "BI",
          "BJ",
          "BL",
          "BM",
          "BN",
          "BO",
          "BQ",
          "BR",
          "BS",
          "BT",
          "BV",
          "BW",
          "BY",
          "BZ",
          "CA",
          "CD",
          "CF",
          "CG",
          "CH",
          "CI",
          "CK",
          "CL",
          "CM",
          "CN",
          "CO",
          "CR",
          "CV",
          "CW",
          "CY",
          "CZ",
          "DE",
          "DJ",
          "DK",
          "DM",
          "DO",
          "DZ",
          "EC",
          "EE",
          "EG",
          "EH",
          "ER",
          "ES",
          "ET",
          "FI",
          "FJ",
          "FK",
          "FO",
          "FR",
          "GA",
          "GB",
          "GD",
          "GE",
          "GF",
          "GG",
          "GH",
          "GI",
          "GL",
          "GM",
          "GN",
          "GP",
          "GQ",
          "GR",
          "GS",
          "GT",
          "GU",
          "GW",
          "GY",
          "HK",
          "HN",
          "HR",
          "HT",
          "HU",
          "ID",
          "IE",
          "IL",
          "IM",
          "IN",
          "IO",
          "IQ",
          "IS",
          "IT",
          "JE",
          "JM",
          "JO",
          "JP",
          "KE",
          "KG",
          "KH",
          "KI",
          "KM",
          "KN",
          "KR",
          "KW",
          "KY",
          "KZ",
          "LA",
          "LB",
          "LC",
          "LI",
          "LK",
          "LR",
          "LS",
          "LT",
          "LU",
          "LV",
          "LY",
          "MA",
          "MC",
          "MD",
          "ME",
          "MF",
          "MG",
          "MK",
          "ML",
          "MM",
          "MN",
          "MO",
          "MQ",
          "MR",
          "MS",
          "MT",
          "MU",
          "MV",
          "MW",
          "MX",
          "MY",
          "MZ",
          "NA",
          "NC",
          "NE",
          "NG",
          "NI",
          "NL",
          "NO",
          "NP",
          "NR",
          "NU",
          "NZ",
          "OM",
          "PA",
          "PE",
          "PF",
          "PG",
          "PH",
          "PK",
          "PL",
          "PM",
          "PN",
          "PR",
          "PS",
          "PT",
          "PY",
          "QA",
          "RE",
          "RO",
          "RS",
          "RU",
          "RW",
          "SA",
          "SB",
          "SC",
          "SE",
          "SG",
          "SH",
          "SI",
          "SJ",
          "SK",
          "SL",
          "SM",
          "SN",
          "SO",
          "SR",
          "SS",
          "ST",
          "SV",
          "SX",
          "SZ",
          "TA",
          "TC",
          "TD",
          "TF",
          "TG",
          "TH",
          "TJ",
          "TK",
          "TL",
          "TM",
          "TN",
          "TO",
          "TR",
          "TT",
          "TV",
          "TW",
          "TZ",
          "UA",
          "UG",
          "US",
          "UY",
          "UZ",
          "VA",
          "VC",
          "VE",
          "VG",
          "VN",
          "VU",
          "WF",
          "WS",
          "XK",
          "YE",
          "YT",
          "ZA",
          "ZM",
          "ZW",
          "ZZ",
        ],
      },
    });

    if (error) {
      console.warn("Error:", error);
      setStripeError(error);
      setLoading(false);
    }
  };

  return (
    <>
      {stripe ? (
        <button
          disabled={loading}
          className='add-to-cart text-lg text-secondary italic'
          id={`checkout-button-${sku}`}
          role='link'
          type='button'
          onClick={handleClick}>
          {price} + {shipping} euros
        </button>
      ) : (
        "Loading..."
      )}
      {stripeError ? <div id='error-message'>{stripeError}</div> : null}
    </>
  );
};

export default StripeButton;
